import React from 'react';
import SavingsList from './components/SavingsList';

function App() {
  // You can set these from state, props, context, etc.
  const userStandard = 'Comfortable';
  const userExperience = 'L3';

  return (
    <div className="App">
      <SavingsList userStandard={userStandard} userExperience={userExperience} />
    </div>
  );
}

export default App;
