import React, { useState } from 'react';
import savingsData from '../savings_data.json';
import './SavingsList.css';

function SavingsList() {
    // State to keep track of the selected values
		const [userStandard, setUserStandard] = useState('Comfortable');
		const [userExperience, setUserExperience] = useState('L3');
	
		// Handler functions to update state
		const handleStandardChange = (event) => {
			setUserStandard(event.target.value);
		};
	
		const handleExperienceChange = (event) => {
			setUserExperience(event.target.value);
		};

	// Compute the key to retrieve the correct list
	const key = `${userStandard}-${userExperience}`;
	const userSpecificList = savingsData[key] || [];
		
  return (
		<div className="main-content">
			<header className="header">
				<h1>Code Capitals</h1>
				<h2>Saving Rates For Big Tech Software Engineers Across The World</h2>
				<p>
					For entry-level, mid-level and senior big tech & FAANg Sofware Engineers, based on lifestyle. 
				</p>
				<p>
					To read our analysis based on this information, check
					<a href="https://open.substack.com/pub/theeuropeanengineer/p/codecapitalscom-expands-to-usa-and" target="_blank" rel="noopener noreferrer">this article</a>.
				</p>
			</header>

			<div className="form-container">
				<div className="form-group">
					<div>
						<label htmlFor="lifestyle-select">Lifestyle:</label>
						<select id="lifestyle-select" value={userStandard} onChange={handleStandardChange}>
							<option value="Frugal">Frugal</option>
							<option value="Comfortable">Comfortable</option>
							<option value="Luxurious">Luxurious</option>
						</select>
					</div>
					<div>
						<label htmlFor="seniority-select">Seniority:</label>
						<select id="seniority-select" value={userExperience} onChange={handleExperienceChange}>
							<option value="L3">L3 (New Grad)</option>
							<option value="L4">L4 (Mid Level)</option>
							<option value="L5">L5 (Senior)</option>
						</select>
					</div>
				</div>
			</div>

			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>#</th>
							<th>City</th>
							<th>Yearly Savings</th>
						</tr>
					</thead>
					<tbody>
						{userSpecificList.map((item, index) => (
							<tr key={index}>
								<td>{index + 1}</td>
								<td>{item.city}</td>
								<td>{item.savings}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);	
};

export default SavingsList;